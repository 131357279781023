// React
import React, { useEffect, useState } from 'react';
import { UseModalContext } from "../context/UseModalContext";
import { isMobile, isDesktop } from 'react-device-detect';
import { connect } from 'react-redux';
import { UseCntContext } from '../context/UseCntContext';
import { UseUserContext } from '../context/UserContext';
import { addToCart } from '../store/actions/cartAction';
// FancyApps
import { Panzoom } from '@fancyapps/ui/dist/panzoom/panzoom.esm.js';  
import { Carousel } from '@fancyapps/ui/dist/carousel/carousel.esm.js';
import '@fancyapps/ui/dist/panzoom/panzoom.css';
import '@fancyapps/ui/dist/carousel/carousel.css';
// Modais
import ProductModal from './modal/ProductModal';
import '../assets/css/component/main.css';
//Presentation
import Slide from './presentation/Slide';
import { useLocation } from 'react-router-dom';

let kapoZoomCarousel;

const Main = ({addToCart}) => {

  const { variaveisFornecedor } = UseUserContext();

  const location = useLocation()

  const { setCnt } = UseCntContext();//pagina que será mostrada na tela
  const { pagecnt, setPagecnt } = UseModalContext();
  const [productModal, setProductModal] = useState(false);
  const [product, setProduct] = useState(null);
  
  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    kapoZoomCarousel = new Carousel(document.querySelector('#kapoZoom'), {
      infinite: false,
      transition: 'crossfade',
      Dots: false,
      on: {
        initSlide: (_carousel, slide) => {
          slide.panzoom = new Panzoom(slide.el.querySelector('.f-panzoom'), {
            panOnlyZoomed: true,
            dblClick: "toggleMax",
            maxScale: 3,
          });
        },
        change: (carousel, to, from) => {
          setCnt(to+1);
          carousel.pages[to].slides.forEach((slide)=> {
            slide.el.querySelectorAll('button').forEach((pin)=> {
              let animation = pin.dataset.animation
              pin.dataset.animation = ""
              window.requestAnimationFrame(() => {
                pin.dataset.animation = animation
              });
            })
          })
        },
      },
    });
    return () => {
      kapoZoomCarousel.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    let initialPageBySearch = new URLSearchParams(location.search).get("page")
    if (initialPageBySearch) {
      setTimeout(()=> setPagecnt(initialPageBySearch),50)
    }
  }, [location, setPagecnt])

  useEffect(() => {
    if(pagecnt !== -1){
      if(isMobile) {
        kapoZoomCarousel.slideTo(pagecnt-1);
      }
      if(isDesktop) {
        let des_cnt = Math.floor(pagecnt/2);
        kapoZoomCarousel.slideTo(des_cnt);
      }
    }
    setPagecnt(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagecnt]);

  const handlePinClick = (product) => {
    let __product = product
    if (typeof __product.relatives === "string") {
      __product.relatives = JSON.parse(__product.relatives)
    }
    setProduct(__product)
    setProductModal(true);
  }
//array com a sequência das páginas a serem carregadas
  const imageNumbers = Array.from({ length: variaveisFornecedor.qtdpaginas }, (_, index) => index);

  return <>
    <div className='relative mx-auto flex h-full w-full max-w-7xl flex-grow items-center justify-center overflow-hidden max-height-lg:max-w-5xl'>
      <div id="kapoZoom" className='f-carousel'>
        <div className='f-carousel__viewport'>
          {
            imageNumbers.map((count, index) => (
              <Slide
                key={index}
                handlePinClick={handlePinClick}
                count={count}
                index={index}
              ></Slide>
            ))
          }
        </div>
      </div>
    </div>
    <ProductModal productModal={productModal} setProductModal={setProductModal} product={product} setProduct={setProduct} addToCart={addToCart}/>
  </>
}
export default connect(null, { addToCart })(Main);