import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { updateQuantity, removeFromCart } from "../../store/actions/cartAction";
import { UseUserContext } from "../../context/UserContext";
import "../../assets/css/component/modal/cartModal.css";
import ItemCartCard from "./ItemCartCard";
import isEmpty from "../utils/isEmpty";
import EmptyCart from "./EmptyCart";

const CartScreen = ({cartItems, updateQuantity, removeFromCart, setCheckout})=> {
    const {variaveisFornecedor} = UseUserContext();

    const handleCheckout = ()=> {
      setCheckout(true)
    }

    useEffect(()=>{
        if (!isEmpty(cartItems)) 
          localStorage.setItem("cartItems/react-version-v1", JSON.stringify(cartItems))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[cartItems])
    
      const handleChangeQuantity = (itemId, itemCode, itemColor, itemSize, increase) => {
        updateQuantity(itemId, itemCode, itemColor, itemSize, increase);
      };
    
      const handleRemoveItem = (itemId, itemReference, itemSize, itemColor) => {
        removeFromCart(itemId, itemReference, itemSize, itemColor);
      };
    
    return (
        <>
          <div className="modal-c-body overflow">
          {isEmpty(cartItems) ? (
              <EmptyCart/>
          ) : (
              <ul>
              {cartItems.map((item, index) => (
                <ItemCartCard item={item} handleChangeQuantity={(increase)=> handleChangeQuantity(item.id, item.code, item.color, item.size, increase)} handleRemoveItem={handleRemoveItem} key={index}/>
              ))}
              </ul>
          )}
          </div>
          <div className="modal-c-footer" >
            <button className={isEmpty(cartItems) ? "" : "allowed-button"} onClick={isEmpty(cartItems) ? ()=>{} : handleCheckout}>{variaveisFornecedor.botao_enviar_para}</button>
          </div>
        </>
      );
}

const mapStateToProps = (state) => ({
    cartItems: state.cart.cartItems,
});

export default connect(mapStateToProps, { updateQuantity, removeFromCart })(CartScreen);