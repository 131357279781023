import React, { useRef, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';

import { UseUserContext } from '../../context/UserContext';
import { UseCntContext } from '../../context/UseCntContext';

import PinButton from './PinButton';
import ClickableMask from './ClickableMask';
import Etiqueta from './Etiqueta';

import CDN from "../../classes/CDN"

const Slide = ({ handlePinClick, count, index })=> {
    const { variaveisFornecedor } = UseUserContext();
    const { cnt } = UseCntContext();//pagina que será mostrada na tela

    //const areaTotal = useRef(null);

    var cdn = new CDN(variaveisFornecedor.id_fornecedor)
    var {getPage} = cdn.pages(variaveisFornecedor.id)
    var {getPin} = cdn.icons
    
    var catalog_id = variaveisFornecedor.id;
    var ordemPaginas = variaveisFornecedor.ordemPaginas; //sequência das páginas
    var id_fornecedor = variaveisFornecedor.id_fornecedor;
    var currentPage = ordemPaginas[count].split(".")[0]

    let paginaPar = ordemPaginas[index]?.split('.')[0]
    let paginaImpar = ordemPaginas[index + 1]?.split('.')[0]

    //teste para carregamento das áreas
    const rlvRef = useRef()
    const [refVisible, setRefVisible] = useState(false)

    useEffect(() => {
        if (!refVisible) { 
          return
        }
        // detected rendering
      }, [refVisible])

    const handleScroll = (e) => {
        const buttonElements = document.querySelector('.f-carousel__nav')?.querySelectorAll('.f-button');
        buttonElements?.forEach(function(button) {
            const kapobtn = document.querySelector('.is-selected').querySelector('.f-panzoom').getAttribute("class")
            if(kapobtn !== "f-panzoom" || kapobtn !== "f-panzoom can-zoom_in"){
            button.style.display = "none";
            }
        });
        setTimeout(() => {
            buttonElements?.forEach(function(button) {
            const kapobtn = document.querySelector('.is-selected').querySelector('.f-panzoom').getAttribute("class")
            if(kapobtn === "f-panzoom" || kapobtn === "f-panzoom can-zoom_in"){
                button.style.display = "flex";
            }
            });
        }, 400);
    };

    const handleClick = (e) => {
        setTimeout(() => {
            document.querySelector('.f-carousel__nav')
                ?.querySelectorAll('.f-button')
                ?.forEach(function(button) {
                    const kapobtn = document.querySelector('.is-selected').querySelector('.f-panzoom').getAttribute("class")
                    if(kapobtn === "f-panzoom" || kapobtn === "f-panzoom can-zoom_in"){
                        button.style.display = "flex";
                    }else{
                        button.style.display = "none";
                    }
            });
        }, 1000);
    };

    return (
        (isDesktop && variaveisFornecedor.qtdpaginas > 1 && cnt <= variaveisFornecedor.qtdpaginas) ? (
            // Página para Desktop
            (index % 2 === 0) ? (
                <div className='f-carousel__slide'>
                    <div className='f-panzoom' onWheel={handleScroll} onClick={handleClick}>  
                        <div className='f-panzoom__content relative' ref={el => { rlvRef.current = el; setRefVisible(!!el); }}>
                            {
                                count < 5 ? (
                                <>
                                    <img 
                                        key={paginaPar} 
                                        //src={`../assets/catalog/${categoryId}/paginas/${paginaPar}.webp`} 

                                        src={getPage(paginaPar)}

                                        alt={`${paginaPar}`} 
                                    />
                                    <img 
                                        key={paginaImpar} 
                                        //src={`../assets/catalog/${categoryId}/paginas/${paginaImpar}.webp`} 

                                        src={getPage(paginaImpar)}

                                        alt={`${paginaImpar}`}
                                    />
                                </> 
                                )
                                :
                                <>
                                    <img 
                                        key={paginaPar} 
                                        //data-lazy-src={`../assets/catalog/${categoryId}/paginas/${paginaPar}.webp`} 

                                        data-lazy-src={getPage(paginaPar)}

                                        alt={`${paginaPar}`} 
                                    />
                                    <img 
                                        key={paginaImpar} 
                                        //data-lazy-src={`../assets/catalog/${categoryId}/paginas/${paginaImpar}.webp`} 

                                        data-lazy-src={getPage(paginaImpar)}

                                        alt={`${paginaImpar}`}
                                    />
                                </>
                            }
                            {
                            variaveisFornecedor.pins
                            ?.filter(pin => String(pin.image_number) === paginaPar || String(pin.image_number) === paginaImpar)
                            .map(pin =>
                                <PinButton
                                key={pin.id}  
                                area={rlvRef.current}
                                left={
                                    (String(pin.image_number) === paginaPar) ?  
                                        `${pin.left / 2}%` 
                                        : 
                                        `${(pin.left / 2) + 50}%`
                                    }
                                top={`${pin.top}%`}
                                size={pin.size}
                                animation={pin.animation}
                                icone={getPin(pin.button_number)}
                                handleClick={()=>handlePinClick(pin)}
                                ></PinButton>
                            )
                            }
                            {                         
                            variaveisFornecedor.clickableMasks
                            ?.filter(mask => String(mask.kapo_name.split('.')[0]) === paginaPar || String(mask.kapo_name.split('.')[0]) === paginaImpar)
                            .map(mask =>
                                <ClickableMask
                                paginas={2}
                                area={rlvRef.current}
                                    key={mask.id}
                                    //desktop / 2
                                    width={`${parseFloat(mask.widthperc)}`}
                                    height={`${mask.heightperc}%`}
                                    direita={
                                    (String(mask.kapo_name.split('.')[0]) === paginaPar) ?  
                                        0 
                                        : 
                                        1
                                        }
                                    left={`${parseFloat(mask.pos_left)}`}    
                                    top={`${mask.pos_top}%`}
                                    link={mask.kapo_link}
                                ></ClickableMask>)
                            }
                            {
                            variaveisFornecedor.etiquetas
                            ?.filter(etiqueta => String(etiqueta.image_number) === paginaPar || String(etiqueta.image_number) === paginaImpar)
                            .map(etiqueta =>
                                <Etiqueta
                                key={etiqueta.id}  
                                left={
                                    (String(etiqueta.image_number) === paginaPar) ?  
                                        `${etiqueta.left / 2}%` 
                                        : 
                                        `${(etiqueta.left / 2) + 50}%`
                                    }
                                top={`${etiqueta.top}%`}
                                icone={`${id_fornecedor}/${catalog_id}/etiquetas/${etiqueta.alvo}`} 
                                ></Etiqueta>
                            )
                            }
                        </div>
                    </div>
                </div>
            ) : null
        ) 
        :
        // Página para Mobile
        <div className='f-carousel__slide'>
            <div className='f-panzoom' onWheel={handleScroll} onClick={handleClick}>  
                <div className='f-panzoom__content relative' ref={el => { rlvRef.current = el; setRefVisible(!!el); }}>
                    {
                    count < 3 ? 
                    <img 
                        key={currentPage} 
                        //src={`../assets/catalog/${categoryId}/paginas/${ordemPaginas[count]}.webp`} 

                        src={getPage(currentPage)}

                        alt={`${currentPage}`} 
                    />
                    :
                    <img 
                        key={currentPage} 
                        //data-lazy-src={`../assets/catalog/${categoryId}/paginas/${ordemPaginas[count]}.webp`} 

                        data-lazy-src={getPage(currentPage)}

                        alt={`${currentPage}`} 
                    />
                    }
                    {
                    cnt === count || cnt === count + 1 || cnt === count + 2 ?
                    variaveisFornecedor.pins
                        ?.filter(pin => String(pin.image_number) === currentPage)
                        .map(pin => 
                        <PinButton
                            key={pin.id}  
                            area={rlvRef.current}
                            left={`${pin.left}%`}
                            top={`${pin.top}%`}
                            size={pin.size}
                            animation={pin.animation}
                            icone={getPin(pin.button_number)}
                            handleClick={()=>handlePinClick(pin)}
                        ></PinButton>
                        ) 
                    : null
                    }
                    {
                    cnt === count || cnt === count + 1 || cnt === count + 2 ?
                    variaveisFornecedor.clickableMasks
                        ?.filter(mask => String(mask.kapo_name.split('.')[0]) === currentPage)
                        .map(mask =>
                        <ClickableMask
                            //paginas={1}
                            area={rlvRef.current}
                            key={mask.id}
                            width={`${parseFloat(mask.widthperc)}`}
                            height={`${mask.heightperc}%`}
                            // width={`${mask.kapo_width}px`}
                            // height={`${mask.kapo_height}px`}
                            left={`${parseFloat(mask.pos_left)}`}
                            top={`${mask.pos_top}%`}
                            link={mask.kapo_link}
                        ></ClickableMask>)
                    : null
                    }
                    {
                    variaveisFornecedor.etiquetas
                        ?.filter(etiqueta => String(etiqueta.image_number) === currentPage)
                        .map(etiqueta =>
                            <Etiqueta
                            key={etiqueta.id}  
                            left={`${etiqueta.left}%`}
                            top={`${etiqueta.top}%`}
                            icone={`${id_fornecedor}/${catalog_id}/etiquetas/${etiqueta.alvo}`} 
                            ></Etiqueta>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Slide;