import { Link, useSearchParams } from "react-router-dom";

const ClickableMask = ({ left, top, width, height, link, area, paginas=1, direita=0 })=> {
    const [searchParams,] = useSearchParams()

    if (!area) {
        return null
    }

    const canRedirect = searchParams.get("noredirect") === null || searchParams.get("noredirect") === false
    const url = new URL(link)

    const toURL = window.location.protocol + "//" + window.location.host + url.pathname + (!canRedirect ? "?noredirect" : "")
    const calculatedLeft = paginas > 1 ? direita ? `calc(${left}% + 50%)` : `calc(${left}% - 50%)` : left + "%"

    return (
        <Link to={!canRedirect ? toURL : url.href} target={url.host !== window.location.host && canRedirect ? "_blank" : "_self"} rel="noreferrer"
            style={{
                position: "absolute",
                background: "transparent",
                width: paginas > 1 ? width / 2 + "%" : width + "%",
                height,
                left: calculatedLeft,
                top
            }} 
        > </Link>
    )
}

export default ClickableMask;