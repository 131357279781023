import React, { useRef, useState, useEffect } from "react";
import {Outlet, useLocation} from "react-router-dom"
import ReactGA from "react-ga4"
import Loader from './component/presentation/Loader'
import Header from "./component/Header";
import MenuModal from "./component/modal/MenuModal";
import SearchModal from "./component/modal/SearchModal";
import CartModal from "./component/modal/CartModal";
import ShareModal from "./component/modal/ShareModal";
import WelcomeModal from "./component/modal/WelcomeModal";
import { ModalContextProvider } from "./context/UseModalContext";
import { CNTContextProvider } from "./context/UseCntContext";
import { UserContextProvider } from "./context/UserContext";
import { Provider } from 'react-redux';
import store from './store';
import axios from "axios";
import CDN from "./classes/CDN"
import { HelmetProvider } from 'react-helmet-async';
import SEO from "./component/presentation/Seo";
import Error404Page from "./component/error/Error404Page";

function Home() {
  const helmetContext = {};
  const [welcome, setWelcome] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [client, setClient] = useState("");
  const [data, setData] = useState(null);
  const elementRef = useRef(null);

  const path = useLocation().pathname
  
  const API_URL = new URL('https://test.useminari.com/api'); // TEST ENVIRONMENT
  //const API_URL = new URL('https://api.useminari.com/api'); // PRODUCTION ENVIRONMENT
  // const API_URL = new URL("http://localhost:8005/api") // DEVELOPER ENVIRONMENT
  
  // Função para fazer a chamada ao backend e buscar os dados do usuário
  // carrega as variáveis globais do cliente
  async function fetchEntries() {
    try {
      const response = await axios.get(`${API_URL}/inicializacao`, { //consulta arquivo inicial json
      //const response = await axios.get(`${API_URL}/inicializacao_json`, { //consulta arquivo inicial json
        params: { 
          originURL: window.location.href.split('?')[0],
          // originURL: 'https://online.golfran.com.br' + window.location.pathname, // Para testes da GOLFRAN
          //originURL: 'https://catalogo.aromavitrine.com.br' + window.location.pathname // Para testes da 
          //originURL:'https://rovitex.useminari.com' + window.location.pathname, // Para testes da ROVITEX
          //black_verao_infantil_outlet_24
          // originURL: 'https://catalogo.useminari.com' + window.location.pathname, // Para testes de catálogos sem domínio ou subdomínio
          pesquisaURL: new URLSearchParams(window.location.search).get("pesquisa") || null
        },
      });

    //const dadosIP = await axios.get("https://geolocation-db.com/json/");
    let dadosIP={}
    dadosIP.data = {
      city:'ND',
      country_code:'BR',
      country_name:'Brasil',
      IPv4:'0',
      latitude:0,
      longitude:0,
      state:'ND'
    }
    
      response.data.url = API_URL;
      response.data.dadosIp = dadosIP.data

      // const infoCliente ={
      //   dadosIP: dadosIP.data,
      //   fornecedor: response.data.id_fornecedor,
      //   id_catalogo: response.data.id
      // }
      // try {
      //   let response = await axios.post(`${API_URL}/ip_acesso`, { //inclui as informações do cliente na base de dados
      //     data: { 
      //       infoCliente 
      //     },
      //   });
      // } catch (error) {
      //   console.error(`Error saving IP ${error}`)
      // }
      setData(response.data)
    } catch (error) {
      console.error(error);
      setError(true)
    }
  }

  function initialize() {
    let cdn = new CDN(data.id_fornecedor)
    document.title = data.nome_fornecedor
    document.querySelector("link[rel*='icon']").href = cdn.icons.favicon

    ReactGA.initialize(data.ganalytics)
    ReactGA.send({ hitType: "pageview", page: path, title: document.title })

    if (validateClient()) {
      setWelcome(false)
    }
    setLoading(false);
  }

  function validateClient() {
    // valida se ja existe as informações obrigatórias
    let client = JSON.parse(localStorage.getItem("client"))

    if (client) {
      if (data.obr_nome) {
        if (!client.hasOwnProperty("nome")) {
          return false
        }
      }
      if (data.obr_telefone) {
        if (!client.hasOwnProperty("telefone")) {
          return false
        }
      }
      if (data.obr_cnpj) {
        if (!client.hasOwnProperty("cnpj")) {
          return false
        }
      }
      setClient(client)

      return true
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchEntries()
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    if (data)
      initialize()
    // eslint-disable-next-line
  }, [data]);

  return (
    !loading ? (
      <HelmetProvider context={helmetContext}>
        <Provider store={store}>
          <div>
            <SEO
              type='website'
              dados={data} 
            />
          </div>
            <div className="bg-white" style={{
              height: "100dvh",
              width: "100dvw"
            }} ref={elementRef}>
              <UserContextProvider data={data}>
                {welcome ? (
                  <WelcomeModal
                    welcome={welcome} 
                    setWelcome={setWelcome} 
                    setClient={setClient}
                  />
                ) : 
                  <ModalContextProvider>
                    <CNTContextProvider>
                      <Header/>
                      <Outlet/>
                      <MenuModal elementRef={elementRef}/>
                    </CNTContextProvider>
                    <SearchModal/>
                    <CartModal client={client}/>
                    <ShareModal/>
                  </ModalContextProvider>
                }
              </UserContextProvider>
            </div>
          </Provider>
        </HelmetProvider>
    ) : error ? <Error404Page/> : <Loader text={"Aguarde enquanto carregamos seu catálogo..."}/>
  );
}
export default Home;